import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { route } from '../utils/helper'

const CategoryList = () => {
  const data = useStaticQuery(graphql`
    query CategoriesListQuery {
      allContentfulCategory(sort: {fields: [name]}) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `)

  return (
    <div>
      <div className="font-bold text-lg border-b-4 border-yellow-500 pb-2">CATEGORIES</div>
      <div className="mt-6 space-y-2">
        {data.allContentfulCategory.edges.map(({ node }) => {
          return (
            <Link
              className="flex items-center justify-between text-gray-500 border-b py-2 mt-1"
              to={route('categories/{category}', node)}
              key={node.id}
            >
              <div>{node.name}</div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default CategoryList
