import React from 'react'
import { Link } from 'gatsby'

const Pager = ({prevPageUrl, nextPageUrl}) => (
  <div className="flex justify-between">
    <div className="px-6">
      {prevPageUrl && (
        <Link to={prevPageUrl} className="flex items-center space-x-4 text-gray-400">
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            ></path>
          </svg>
          <div className="font-bold">Previous</div>
        </Link>
      )}
    </div>
    <div className="px-6">
      {nextPageUrl && (
        <Link to={nextPageUrl} className="flex items-center space-x-4 text-gray-400">
          <div className="font-bold">Next</div>
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            ></path>
          </svg>
        </Link>
      )}
    </div>
  </div>
)

export default Pager
