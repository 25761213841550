import React from 'react'

const Footer = () => (
  <footer className="footer w-full bg-gray-600 text-white mt-20">
    <div className="container mx-auto py-16">
      <div className="flex justify-center">
        <a className="block mx-3 md:mx-6" href="https://twitter.com/xzxzyzyz">
          <img alt="" src={'/svg/twitter.svg'} />
        </a>
        <a className="block mx-3 md:mx-6" href="https://github.com/xzxzyzyz">
          <img alt="" src={'/svg/github.svg'} />
        </a>
        <a className="block mx-3 md:mx-6" href="https://instagram.com/xzxzyzyz">
          <img alt="" src={'/svg/instagram.svg'} />
        </a>
      </div>
      <div className="mt-10">
        <div className="text-center text-sm font-semibold">© Xzxzyzyz</div>
      </div>
    </div>
  </footer>
)

export default Footer
