import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout-with-author'
import BlogListItem from '../components/blog-list-item'
import Pager from '../components/pager'
import SEO from '../components/seo'

const BlogTemplate = ({data, location, pageContext}) => {
  const posts = data.allContentfulPost.edges
  const nextPageUrl = pageContext.nextPageUrl
  const prevPageUrl = pageContext.prevPageUrl

  return (
    <Layout location={location}>
      <SEO title={`Post`} />
      <div className="space-y-8">
        {posts.map(({ node }) => <BlogListItem post={node} key={node.slug} />)}
      </div>
      <div className="mt-12">
        <Pager nextPageUrl={nextPageUrl} prevPageUrl={prevPageUrl} />
      </div>
    </Layout>
  )
}

export default BlogTemplate

export const pageQuery = graphql`
  query BlogQuery($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          publishDate
          heroImage {
            gatsbyImageData(placeholder: BLURRED)
          }
          body {
          childMarkdownRemark {
            html
          }
        }
        }
      }
    }
  }
`
