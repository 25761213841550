import React from 'react'
import { Link } from 'gatsby'
import dayjs from 'dayjs'
import { GatsbyImage } from "gatsby-plugin-image";
import { route } from '../utils/helper'

const BlogListItem = ({ post }) => {
  const publishDate = dayjs(post.publishDate)

  return (
    <Link
      to={route('posts/{post}', post)}
      className="flex flex-col md:flex-row items-start overflow-hidden"
      key={post.slug}
    >
      <GatsbyImage
        image={post.heroImage.gatsbyImageData}
        className="w-full h-full md:w-2/5 lg:w-1/3 sm:rounded-t md:rounded md:mr-4"
        alt=""
      />
      <div className="w-full bg-white md:w-3/5 lg:w-2/3 px-4 pt-3 pb-4 sm:rounded-b md:rounded">
        <time className="text-gray-500 text-xs font-semibold">{publishDate.format('YYYY-MM-DD')}</time>
        <h2 className="mt-1 text-lg font-semibold text-gray-700">{post.title}</h2>
        <div className="mt-2 text-sm text-gray-500 leading-6 line-clamp-3">
          {post.body.childMarkdownRemark.html.replace(/<[^>]+>/g, '')}
        </div>
      </div>
    </Link>
  )
}

export default BlogListItem
