import React from 'react'
import Layout from '../components/layout'
import LatestBlogPosts from '../components/latest-blog-posts'
import CategoryList from '../components/category-list'
import Author from '../components/author'

class LayoutWithAuthor extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Layout location={this.props.location}>
        <div className="flex flex-col lg:flex-row">
          <main className="w-full lg:w-3/4 md:px-6 space-y-8">{children}</main>
          <section className="w-full lg:w-1/4 px-6 mt-20 lg:mt-0">
            {/* Author */}
            <Author />
            {/* Latest posts */}
            <div className="mt-12">
              <LatestBlogPosts />
            </div>
            {/* Categories */}
            <div className="mt-12">
              <CategoryList />
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default LayoutWithAuthor
