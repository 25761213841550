import React from 'react'
import { Link } from 'gatsby'
import { route } from '../utils/helper'

const CategoryLinkBadge = ({category}) => (
  <Link
    className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-500 mr-2"
    to={route('categories/{category}', category)}
  >
    #{category.name}
  </Link>
)

export default CategoryLinkBadge
