const dayjs = require('dayjs')

const route = (name, node) => {
  if (name === 'home') {
      return '/'
  }

  if (name === 'posts') {
      return '/posts/'
  }

  if (name === 'posts/{post}') {
      const publishDate = dayjs(node.publishDate)

      return `/${publishDate.format('YYYY')}/${publishDate.format('MM')}/${node.slug}/`
  }

  if (name === 'categories/{category}') {
    return `/categories/${node.slug}/`
  }

  throw new Error(`${name} route is not difined.`)
}

const paginate = (path, page = 0) => page === 0 ? path : path + `page/${page + 1}/`

const truncate = (text, length, ellipsis = '...') => {
  return text.length >= length
    ? text.slice(0, length - ellipsis.length) + ellipsis
    : text
}

module.exports = {
  route,
  paginate,
  truncate,
}
