import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import dayjs from 'dayjs'
import { route } from '../utils/helper'
import { GatsbyImage } from "gatsby-plugin-image";

const LatestBlogPost = () => {
  const data = useStaticQuery(graphql`
    query LatestBlogPostsQuery {
      allContentfulPost(limit: 4, sort: { fields: [publishDate], order: DESC }) {
        edges {
          node {
            title
            slug
            publishDate
            heroImage {
              gatsbyImageData(
                placeholder: BLURRED,
                layout: FULL_WIDTH
              )
            }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <div className="font-bold text-lg border-b-4 border-yellow-500 pb-2">RECENT POSTS</div>
      <div className="mt-6 space-y-4">
        {data.allContentfulPost.edges.map(({ node }) => {
          const publishDate = dayjs(node.publishDate)
          return (
            <div key={node.slug}>
              <div className="rounded bg-white overflow-hidden shadow-lg">
                <Link to={route('posts/{post}', node)}>
                  <GatsbyImage image={node.heroImage.gatsbyImageData} className="w-full aspect-w-16 aspect-h-7" alt="" />
                </Link>
                <div className="px-4 pt-3 pb-4">
                  <time className="text-gray-500 text-xs font-semibold">{publishDate.format('YYYY-MM-DD')}</time>
                  <h3 className="text-sm font-semibold leading-normal mt-1">
                    <Link to={route('posts/{post}', node)} className="text-gray-600">
                      {node.title}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LatestBlogPost
