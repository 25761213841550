import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout-with-author'
import BlogPostCard from '../components/blog-post-card'
import Pager from '../components/pager'
import { route, paginate } from '../utils/helper'
import SEO from '../components/seo'

const RootIndex = ({data, location}) => {
  const posts = data.allContentfulPost.edges
  const firstPost = posts[0]
  const otherPosts = posts.filter((post, index) => index !== 0)

  return (
    <Layout location={location}>
      <SEO />
      {/* Posts */}
      <div>
        <BlogPostCard post={firstPost.node} />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-8 lg:gap-x-8">
        {otherPosts.map(({ node }) => {
          return (
            <div key={node.slug}>
              <BlogPostCard post={node} />
            </div>
          )
        })}
      </div>
      {/* Pagination */}
      <div>
        <Pager nextPageUrl={paginate(route('posts'), 1)} />
      </div>
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 7
    ) {
      edges {
        node {
          title
          slug
          publishDate
          heroImage {
            gatsbyImageData(placeholder: BLURRED)
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
