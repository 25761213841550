import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout-with-author'
import CategoryLinkBadge from '../components/category-link-badge'
import BlogListItem from '../components/blog-list-item'
import Pager from '../components/pager'
import SEO from '../components/seo'

const BlogCategoryTemplate = ({data, location, pageContext}) => {
  const posts = data.allContentfulPost.edges
  const category = data.contentfulCategory
  const nextPageUrl = pageContext.nextPageUrl
  const prevPageUrl = pageContext.prevPageUrl

  return (
    <Layout location={location}>
      <SEO title={category.name} />
      <div className="mx-6">
        <CategoryLinkBadge category={category} />
      </div>
      <div className="space-y-8">
        {posts.map(({ node }) => <BlogListItem post={node} key={node.slug} />)}
      </div>
      <div className="mt-12">
        <Pager nextPageUrl={nextPageUrl} prevPageUrl={prevPageUrl} />
      </div>
    </Layout>
  )
}

export default BlogCategoryTemplate

export const pageQuery = graphql`
  query BlogCategoryQuery($categoryId: String!, $skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { category: { id: { eq: $categoryId }}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          publishDate
          heroImage {
            gatsbyImageData(
              placeholder: BLURRED,
              layout: FULL_WIDTH
            )
          }
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    contentfulCategory(id: {eq: $categoryId}) {
      id
      name
      slug
    }
  }
`
