import React from 'react'
import { Helmet } from 'react-helmet'

const Container = ({ children }) => (
  <div className="font-sans text-gray-700 bg-gray-100 antialiased tracking-wide">
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Noto+Sans+JP" rel="stylesheet"></link>
    </Helmet>
    {children}
  </div>
)

export default Container
