import React from 'react'

const Author = () => (
  <div className="relative max-w-sm mx-auto">
    <div className="absolute inset-0">
      <div className="flex justify-center">
        <img className="block h-24 rounded-full mx-auto" alt="" src={'/img/avatar.png'} />
      </div>
    </div>
    <div className="pt-12">
      <div className="bg-white shadow rounded overflow-hidden pt-16 pb-4 px-4">
        <div className="text-center">
          <div className="font-semibold">Kentaro Nishiguchi</div>
          <div className="text-sm text-grey-darker mt-2">
            もしかして: <a href="https://laravel.com/">Laravel</a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Author
