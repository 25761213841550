/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { truncate } from '../utils/helper'

const SEO = ({ description, lang, meta, title, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const globalMeta = []

  const ogMeta = [
    {
      property: `og:site_name`,
      content: defaultTitle,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      name: `description`,
      content: truncate(metaDescription, 120),
    },
    {
      property: `og:description`,
      content: truncate(metaDescription, 120),
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:loale`,
      content: lang,
    },
  ]
  if (image) {
    ogMeta.push({
      property: `og:image`,
      content: image,
    })
  }

  const twitterMeta = [
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata?.social?.twitter || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: truncate(metaDescription, 120),
    },
  ]
  if (image) {
    twitterMeta.push({
      name: `twitter:image`,
      content: image,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={defaultTitle}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={globalMeta.concat(ogMeta).concat(twitterMeta).concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
}

export default SEO
