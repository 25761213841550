import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/layout'
import { route } from '../utils/helper'
import { TwitterShareButton, TwitterIcon, HatenaShareButton, HatenaIcon } from 'react-share'
import dayjs from 'dayjs'
import CategoryLinkBadge from '../components/category-link-badge'
import SEO from '../components/seo'

const BlogPostTemplate = ({data, location, pageContext}) => {
  const post = data.contentfulPost
  const { previous, next } = data
  const siteUrl = data.site.siteMetadata.siteUrl
  const socialTwitter = data.site.siteMetadata.social.twitter
  const url = route('posts/{post}', post)
  const shareUrl = `${siteUrl}${url}`
  const publishDate = dayjs(post.publishDate)

  return (
    <Layout location={location}>
      <SEO
        title={post.title}
        image={`https:${post.heroImage.file.url}`}
        description={post.body.childMarkdownRemark.html.replace(/<[^>]+>/g, '')}
      />
      <div className="w-full md:px-6">
        <article>
          <div className="bg-white rounded overflow-hidden shadow-lg">
            {/* Hero Image */}
            <GatsbyImage image={post.heroImage.gatsbyImageData} alt="" />

            <div className="px-6 md:px-12 py-12">
              <div className="mb-8 flex justify-between">
                {/* Social Share */}
                <div className="space-x-4">
                  <TwitterShareButton title={post.title} url={shareUrl} via={socialTwitter}>
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                  <HatenaShareButton title={post.title} url={shareUrl}>
                    <HatenaIcon size={32} round={true} />
                  </HatenaShareButton>
                </div>
                {/* Published At */}
                <time
                  className="text-gray-400 text-sm font-semibold"
                  dateTime={publishDate.format('YYYY-MM-DD HH:mm:ss')}
                >
                  {publishDate.format('YYYY-MM-DD')}
                </time>
              </div>

              {/* Body */}
              <div
                dangerouslySetInnerHTML={{
                  __html: `<h1>${post.title}</h1>${post.body.childMarkdownRemark.html}`,
                }}
                className="prose prose-indigo"
              />

              <div className="mt-12">
                <CategoryLinkBadge category={post.category} />
              </div>
            </div>
          </div>
        </article>

        {/* Nav */}
        <div className="mt-12 flex flex-col md:flex-row md:justify-between">
          <div className="flex items-center px-6 mt-4">
            {next && (
              <Link to={route('posts/{post}', next)} className="flex items-center space-x-4 text-gray-400">
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  ></path>
                </svg>
                <div className="font-bold">{next.title}</div>
              </Link>
            )}
          </div>
          <div className="flex items-center px-6 mt-4 ml-auto">
            {previous && (
              <Link to={route('posts/{post}', previous)} className="flex items-center space-x-4 text-gray-400">
                <div className="font-bold">{previous.title}</div>
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $postId: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    contentfulPost(id: { eq: $postId }) {
      id
      title
      slug
      publishDate
      heroImage {
        gatsbyImageData(
          placeholder: BLURRED,
          layout: FULL_WIDTH
        )
        file {
          url
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      category {
        id
        name
        slug
      }
    }
    previous: contentfulPost(id: { eq: $previousPostId }) {
      id
      title
      slug
      publishDate
    }
    next: contentfulPost(id: { eq: $nextPostId }) {
      id
      title
      slug
      publishDate
    }
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
        }
      }
    }
  }
`
