import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = ({data, location}) => {
  return (
    <Layout location={location}>
      <SEO title={`Not Found`} />
      <div className="w-full px-6">
        <div className="text-2xl font-bold">Not Found</div>
        <div className="mt-8">Sorry, the page you are looking for could not be found.</div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`
