import React from 'react'
import { Link } from 'gatsby'
import dayjs from 'dayjs'
import { route } from '../utils/helper'
import { GatsbyImage } from "gatsby-plugin-image";

const BlogPostCard = ({ post }) => {
  const publishDate = dayjs(post.publishDate)

  return (
    <article className="post">
      <div className="rounded bg-white overflow-hidden shadow-lg">
        <div className="px-6 py-6 pb-4">
          <time className="text-gray-500 text-sm font-semibold">{publishDate.format('YYYY-MM-DD')}</time>
          <h2 className="text-xl font-semibold leading-relaxed mt-2">
            <Link to={route('posts/{post}', post)} className="text-gray-700">
              {post.title}
            </Link>
          </h2>
          <div className="text-gray-400 font-semibold text-sm mt-1">Laravel Authenticate Event</div>
        </div>

        <Link to={route('posts/{post}', post)}>
          <GatsbyImage image={post.heroImage.gatsbyImageData} className="w-full aspect-w-16 aspect-h-7" alt="" />
        </Link>

        <div className="px-6 pt-4 pb-6">
          <div className="leading-6 line-clamp-3">{post.body.childMarkdownRemark.html.replace(/<[^>]+>/g, '')}</div>
          <div className="mt-5">
            <Link to={route('posts/{post}', post)} className="text-xs font-semibold">
              続きを読む
            </Link>
          </div>
        </div>
      </div>
    </article>
  )
}

export default BlogPostCard
