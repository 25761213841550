import React from 'react'
import Container from './container'
import Navigation from './navigation'
import Footer from './footer'

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Container>
        <div className="min-h-screen flex flex-col">
          <Navigation />
          <div className="flex-1 w-full mx-auto px-6">
            <div className="flex items-center -mx-6">
              <div className="container max-w-6xl mx-auto pt-12">{children}</div>
            </div>
          </div>
          <Footer />
        </div>
      </Container>
    )
  }
}

export default Layout
